<template>
  <div class="card mb-4" :key="`lesson-card-${lesson_id}-${weekPlan.updated_at}`" v-if="lesson != null">
    <div class="card-header d-flex justify-content-between pt-1 pb-1 pl-1 pr-2 font-weight-bold bg-light" >
      <div>
        <div class="btn-group" v-if="!linkMode">          
          <a class="btn btn-sm py-0 btn-outline-dark" @click="changeTab('task')" v-bind:class="{active: lesson.selected_tab == 'task'}" href="javascript:void(0)">Taak</a>          
          <a v-if="show_comment_field" class="btn btn-sm py-0 btn-outline-dark" @click="changeTab('comment')" v-bind:class="{active: lesson.selected_tab == 'comment'}" href="javascript:void(0)">Opmerking</a>
          <a class="btn btn-sm py-0 btn-outline-dark" @click="changeTab('goal')" v-bind:class="{active: lesson.selected_tab == 'goal'}" href="javascript:void(0)">Doel</a>
          
          
          <a class="btn btn-sm py-0 btn-outline-dark" @click="changeTab('more')" v-bind:class="{active: lesson.selected_tab == 'more'}" href="javascript:void(0)">Meer</a>

        </div>

      </div>


      <div class="align-self-center"  v-tooltip.top-center="`Voor alle niveau's hetzelfde invullen?`" style="font-size: 9pt;" v-if="lesson.lesson_levels.length > 1 && !linkMode">
        <i @click="isLocked = false" v-if="isLocked" class="fas fa-link text-success"></i>
        <i @click="isLocked = true" v-if="!isLocked" class="fas fa-unlink text-muted "></i>
      </div>

      <div class="align-self-center"  v-tooltip.top-center="`Is deze les zichtbaar op de weektaak voor de kinderen?`" style="font-size: 9pt; margin-left: 10px; margin-right: 10px;" v-if="!linkMode">
        <i @click="toggleVisible" v-if="isVisibleForStudent" class="far fa-eye text-success"></i>
        <i @click="toggleVisible" v-if="!isVisibleForStudent" class="far fa-eye-slash text-muted "></i>
      </div>
      
      <div class="small align-self-center" v-if="!hideDay && !lesson.is_clockless">
        <i class="far fa-clock"></i>  {{ day.short_day }} {{ lesson.time }}
      </div>

      <div class="small align-self-center" v-if="!hideDay && lesson.is_clockless">
        <i class="far fa-calendar"></i>  {{ day.day }}
      </div>
    </div>
    <div class="card-body text-center align-items-center" v-if="!day.is_active">
      {{ day.reason_for_inactive}}
    </div>
    <div class="card-body text-left py-2 small" v-if="!hideDay && lesson.is_clockless">
      <strong>{{ lesson.course_name }}</strong>
    </div>
    <div class="list-group  list-group-flush" v-if="!linkMode">
      <WeekPlanItemLevelPerCourse 
        :show_comment_field="show_comment_field"
        :key="lesson_level.id" 
        :lesson_level="lesson_level" 
        :lesson_methods="lesson_methods"
        :tab="lesson.selected_tab"
        :lesson="lesson"
        :isLocked="isLocked"
        class="list-group-item py-0" 
        @lockedUpdate="lockedUpdate"
        @change="saveLesson"
        v-for="lesson_level in lesson.lesson_levels">  
      </WeekPlanItemLevelPerCourse>
    </div>

    <div v-if="linkMode" class="list-group list-group-flush vld-parent">
      <loading :active.sync="lesson.is_loading" loader="dots" color="#007bff" :can-cancel="false" :is-full-page="true"></loading>
      <div :key="`link-group-${lesson.id}-${group.id}`" v-if="group.id != lesson.group_id" v-for="group in course.groups">
        <div :key="wpd.id" v-for="wpd in group.week_plan_days" v-if="wpd">
          <div :key="llesson.id" v-for="llesson in wpd.lessons" v-if="linkIsVisible(wpd.day == day.day)">

            <div class="list-group-item small d-flex justify-content-between align-items-center">
              <span v-bind:class="{'font-weight-bold': wpd.day == day.day}">{{ group.name }}, <span style="text-transform: lowercase;">{{wpd.day}}</span>  {{llesson.time }}</span>
              <span><toggle-button @change="submitLink(llesson)" :value="isLinkedTo(llesson.id)" :height="20" :sync="true" /></span>
            </div>
          </div>
        </div>
        <div class="list-group-item small text-center py-0" v-if="false && group.week_plan_days.length > 0">
          <button v-show="!showAllLinks" class="btn btn-link btn-sm" @click="showAllLinks = true">Toon alle lessen</button>
          <button v-show="showAllLinks" class="btn btn-link btn-sm" @click="showAllLinks = false">Toon alleen lessen op <span class="text-lowercase">{{day.day}}</span></button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Weektaak from '../../packs/weektaak'
import WeekPlanItemLevelPerCourse from '../../components/week_plans/weekPlanItemLevelPerCourse.vue'

// Import component
import loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

export default {

  name: 'perCourseLesson',
  components: {WeekPlanItemLevelPerCourse, loading},
  props: ["lesson_id", "day", "lesson_methods", "linkMode", "course", "show_comment_field", "lessonObject", "hideDay", "clockless"],
  data () {
    return {
      isVisibleForStudent: false,
      isLocked: false,
      activeTab: 'task',
      showAllLinks: true
    }
  },
  computed: {
    lesson(){
      if (this.lessonObject) {
        return this.lessonObject 
      } else {
        for (var i = this.weekPlan.courses.length - 1; i >= 0; i--) {
          if (this.weekPlan.courses[i].id == this.course.id){
            for (var j = this.weekPlan.courses[i].groups.length - 1; j >= 0; j--) {
              let gr = this.weekPlan.courses[i].groups[j]
              for (var k = gr.week_plan_days.length - 1; k >= 0; k--) {
                let wpd = gr.week_plan_days[k]
                if (wpd.id == this.day.id) {
                  for (var l = wpd.lessons.length - 1; l >= 0; l--) {
                    if (wpd.lessons[l].id == this.lesson_id){
                      return wpd.lessons[l]
                    }
                  }
                }
              }
            }
          }
        }
      }
      return null
    },
    weekPlan (){
      return window.store.getters.weekPlanPerCourse
    },
    linkedLessonIds(){
      let arr = []
      for (var i = this.linkedLessons.length - 1; i >= 0; i--) {
        arr.push(this.linkedLessons[i].id)
      }
      return arr
    },
    linkedLessons (){
      if (this.lesson.linked_lessons && this.lesson.linked_lessons.length){
        var arr = []
        for (var a = this.weekPlan.courses.length - 1; a >= 0; a--) {
          var course = this.weekPlan.courses[a]
          if (course.id == this.lesson.course_id){
            for (var b = course.groups.length - 1; b >= 0; b--) {
              var group = course.groups[b]
              for (var c = group.week_plan_days.length - 1; c >= 0; c--) {
                var wpd = group.week_plan_days[c]
                for (var d = wpd.lessons.length - 1; d >= 0; d--) {
                  var lesson = wpd.lessons[d]
                  for (var e = this.lesson.linked_lessons.length - 1; e >= 0; e--) {
                    var linkedLesson = this.lesson.linked_lessons[e]
                    if (lesson.id == linkedLesson.id) {
                      arr.push(lesson)
                    }
                  }
                }
              }
            }
            return arr
          }
        }

        return arr
      } else {
        return []
      }
    }
  },
  created() {
    this.isVisibleForStudent = this.lesson.visible_for_student
  },
  methods: {
    toggleVisible(){
      // console.log('setting visibility')
      if (this.isVisibleForStudent) {
        Weektaak.updateLessonVisible(this.lesson_id, false)
      } else {
        Weektaak.updateLessonVisible(this.lesson_id, true)
      }
      this.isVisibleForStudent = !this.isVisibleForStudent
    },
    linkIsVisible(bool){
      return bool || this.showAllLinks
    },
    submitLink(linkedLesson){
      var ids = this.linkedLessonIds
      if (ids.includes(linkedLesson.id)){
        // console.log("was on, now off")
        ids = Weektaak.arrayRemove(ids, linkedLesson.id)
        var tids = this.weekPlan.temp_linked_lesson_ids
        tids = Weektaak.arrayRemove(tids, linkedLesson.id + this.lesson.id)
        this.weekPlan.temp_linked_lesson_ids = tids
        this.weekPlan.temp_unlinked_lesson_ids.push(this.lesson.id + linkedLesson.id)
      } else {
        // console.log("was off, now on")
        var tids = this.weekPlan.temp_unlinked_lesson_ids
        tids = Weektaak.arrayRemove(tids, linkedLesson.id + this.lesson.id)
        this.weekPlan.temp_unlinked_lesson_ids = tids
        this.weekPlan.temp_linked_lesson_ids.push(this.lesson.id + linkedLesson.id)        
        ids.push(linkedLesson.id)

      }

      this.lesson.is_loading = true
      linkedLesson.is_loading = true
      // setTimeout(function () {
      //   this.lesson.is_loading = false
      //   linkedLesson.is_loading = false
      // }.bind(this), 3000)
      // weekPlanPerCourse
      Weektaak.saveLessonLinksPerCourse(this.lesson.id, {linked_lesson_ids: ids, clockless: this.clockless})
      return true
    },
    isLinkedTo(lessonId){
      if (this.weekPlan.temp_unlinked_lesson_ids.includes(lessonId + this.lesson.id)) {
        return false
      } else if (this.weekPlan.temp_linked_lesson_ids.includes(lessonId + this.lesson.id)) {
        return true
      } else {
        for (var i = this.lesson.linked_lessons.length - 1; i >= 0; i--) {
          let ll = this.lesson.linked_lessons[i]
          if (ll.id === lessonId) {
            return true
          }
        }
      }
      return false
    },
    changeTab (tab) {
      this.lesson.selected_tab = tab
      for (var i = this.linkedLessons.length - 1; i >= 0; i--) {
        var lesson = this.linkedLessons[i]
        lesson.selected_tab = tab
      }

    },
    saveLesson(){
      Weektaak.updateLesson(this.lesson)
    },
    lockedUpdate (lessonLevel, field){
      // console.log("linked, so updating all")
      // console.log(field)
      // console.log(lessonLevel)
      // this.saveLesson()
      // update lesson levels for the same lesson
      for (let i = 0; i < this.lesson.lesson_levels.length; i++){
        if (this.lesson.lesson_levels[i].id == lessonLevel.id){
          // console.log(lessonLevel.id)
          this.lesson.lesson_levels[i].islocked = true
        } else if (this.lesson.lesson_levels[i].student_id == null) {
          this.lesson.lesson_levels[i].islocked = false
          // this.lesson.lesson_levels[i].
          switch(field) {
            case 'task':
              // console.log('updating task')
              this.lesson.lesson_levels[i].task = lessonLevel.task   
              break;
            case 'goal':
              // console.log('updating goal')
              this.lesson.lesson_levels[i].goal = lessonLevel.goal   
              break;
            case 'comment':
              // console.log('updating task')
              this.lesson.lesson_levels[i].comment = lessonLevel.comment
              break;
            case 'lesson_method_id':
              // console.log('updating lesson method')
              this.lesson.lesson_levels[i].lesson_method_id = lessonLevel.lesson_method_id   
              break;
            case 'correct':
              this.lesson.lesson_levels[i].correct = lessonLevel.correct   
              break;
            case 'show_time':
              this.lesson.lesson_levels[i].show_time = lessonLevel.show_time   
              break;
            default:
              // code block
          }
        }
      }
    },
  }
}
</script>

<style lang="css">
.bg-white .nav-link{
  font-weight: 300;
  font-size: 11pt;
}
.bg-white .nav-link{
  color: #000 !important;
}
.bg-dark .nav-link{
  color: #fff;
}
.bg-dark .nav-link.active{
  color: #000 !important;
}
</style>