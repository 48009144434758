<template>
  <div class="planned-note">
    <div class="d-flex justify-content-between px-2">
      <Button @click="New" class="btn btn-primary d-flex ">Nieuwe afspraak</Button>
      <div v-if="!show_all" class="input-group" style="width: 240px;">
        <div class="input-group-prepend">
          <button @click="decrementWeek" class="btn btn-outline-primary"><i class="fa fa-chevron-left"></i></button>
        </div>
        <input type="week" :min="minDate" :max="maxDate" class="form-control" style="border-color: #007bff; color: #007bff; border-top-width: 1px; border-bottom-width: 1px; width: 100px; background: transparent; " @change="LoadNotes()" v-model="selectedWeek" autofocus>        
        <div class="input-group-append">
          <button @click="incrementWeek" class="btn btn-outline-primary"><i class="fa fa-chevron-right"></i></button>
        </div>
      </div>
      <PlannedNotesPrint v-if="!show_all":week="selectedWeek" :planned_note_category_id="planned_note_category_id" :has_notes="notes.length > 0" />


      
    </div>
    
    <div v-if="showBlankSlate" class="bg-light p-3 rounded mt-5 w-50 mx-auto" style="text-wrap: balance;">
      <h3><span class="badge badge-warning float-right">NIEUW 🎉</span> Werken met zorgafspraken</h3> 
      <p>De afspraken die je hier inplant, vind je op drie plekken terug:</p><ul><li>In de weektaak van de betreffende kinderen</li><li>In het weekplan van de leerkracht</li><li>In het overzicht van de specialist</li></ul><p>Je kunt de specialisaties beheren/hernoemen bij <a href="/settings/planned_note_categories">instellingen > zorgcategorieën</a>.</p>
    </div>

    <div class="card shadow-sm mt-4 mx-2" style="overflow: hidden;" v-if="!showBlankSlate">
      <div class="row pt-2" style="overflow: hidden;">
        <div class="col-1 text-center" style="padding-top: 30px;">
        
          <div v-for="time in times" class="d-flex align-items-center justify-content-center fifteen-minutes-block" style="height: 15px; position: relative;">
            <span style="z-index: 1; background: #fff; padding: 2px; font-size: 12px; font-weight: 900;" v-if="time.full_hour">{{ time.time }}</span>
            <span style="z-index: 1; background: #fff; padding: 2px; font-size: 10px;" v-if="!time.full_hour">{{ time.time }}</span>
            <div v-if="!time.full_hour" style="z-index: 0; height: 1px; background: #dedede; width: 100vw; position: absolute; left: 0px; right: 0px;"></div>
            <div v-if="time.full_hour" style="z-index: 0; height: 1px; background: #007bff; width: 100vw; position: absolute; left: 0px; right: 0px;"></div>

          </div>

        </div>

        <div v-for="day in days" v-if="day.on_weekday" class="col" style="border-left:  1px solid #dedede;">
          <div style="height: 30px; font-size: 10pt;" class="text-center">
            {{ day.label }}  
          </div>
          
            <div @click="Edit(note)" v-for="note in notes" v-if="note.starts_at_date == day.iso8601" :style="`cursor: pointer; background:  #c7eafb; opacity: 0.8; border:  1px solid #58c4f8; border-radius: 4px; width:  100%; font-size: 8pt; height: ${note.duration}px; position: absolute; top: ${note.minutes_since_midnight - times[0].minutes_since_midnight + 37}px; left: 0px; vertical-align: middle;`" class="d-flex align-items-center pl-2">
              {{ note.time }}
            </div>
        
        </div>
      </div>
    </div>

    <table v-if="!showBlankSlate" class="table table-sm mt-5" style="font-size: 14px;">
      <thead>
        <tr>
          <th>Dag</th>
          <th style="min-width: 96px;">Datum</th>
          <th>Tijd</th>
          <th v-if="planned_note_category_id == null">Soort</th>
          <th>Leerling(en)</th>
          <th>Notitie</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="planned_note in notes" :key="`note-${planned_note.id}`">
          <td>{{planned_note.short_week_day}}</td>
          <td>
            <p>{{ planned_note.day }} {{ planned_note.show_date}}</p>
            <!-- <time class="icon">
              <em>{{ planned_note.week_day }}</em>
              <strong>{{ planned_note.show_date }}</strong>
              <span>{{ planned_note.day }}</span>
            </time> -->
          </td>
          <td align="left">
            <p>{{ planned_note.starts_at_time}}&nbsp;-&nbsp;{{ planned_note.ends_at_time }}</p>
          </td>
          <td v-if="planned_note_category_id == null">
            <p>{{ planned_note.category.name }}</p>
          </td>
          <td>
            <span v-if="planned_note.students.length > 1" v-tooltip.top-center="planned_note.student_long_string">{{ planned_note.student_string }}</span>

          </td>
          <td>
            <div v-html="planned_note.body"></div>
            <div v-html="planned_note.specialist_body"></div>
          </td>
          <td align="center" width="50">
            <Button @click="Edit(planned_note)" class="btn btn-sm btn-outline-primary">Bewerken</Button>
          </td>
          <td align="center" width="40">
            <Button style="width: 120px;" v-tooltip.right="RepeatTitle(planned_note)" @click="Repeat(planned_note)" class="btn btn-sm" :class="{'btn-outline-success' : !planned_note.is_repeating, 'btn-success' : planned_note.is_repeating}" ><i class="fas fa-redo"></i> <span v-if="!planned_note.is_repeating">Herhalen</span><span v-if="planned_note.is_repeating">Herhaald</span></Button>
          </td>
        </tr>
      </tbody>
    </table>
    
    
    <div class="modal fade" id="modalScreen" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header bg-light">
            <h5 class="modal-title" v-if="IsNew">Nieuwe zorgafspraak</h5>
            <h5 class="modal-title" v-if="!IsNew">Zorgafspraak bewerken</h5>
            <button @click="Cancel" type="button" class="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="ShowRepeatOption">
            <div class="card text-center">
              <div class="card-body animated tada">
                <p>Deze afspraak maakt deel uit van een serie herhaalde afspraken. Wil je alleen deze afspraak wijzigen, of de rest van de reeks (de geselecteere afspraak en nieuwer)?</p>
                <div class="btn-group">
                  <button @click="repeatEditSelection = 'instance'" class="btn btn-outline-primary">Alleen deze afspraak</button>
                  <button @click="repeatEditSelection = 'all'" class="btn btn-outline-primary">De rest van de reeks</button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body" v-if="!ShowRepeatOption">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Datum</label>
                  <input :disabled="repeatEditSelection == 'all'" v-if="theDates == null" type="date" :min="minDate" :max="maxDate" class="form-control" v-model="note.starts_at_date" autofocus>
                  <select v-if="theDates != null" v-model="note.starts_at_date" class="custom-select custom-select-md">
                    <option v-if="note.starts_at_date == null" :value="null">-- Kies een dag --</option>
                    <option :value="date.value" v-for="date in theDates">{{ date.label }}</option>
                  </select>      
                </div>
              </div>
              <div class="col-3 px-1">
                <div class="form-group">
                  <label>Van</label>
                  <input :disabled="!hasMinDate" @blur="SetDefaultEndTime" type="time" min="07:00" max="17:00" class="form-control mx-0" :step="300" v-model="note.starts_at_time">
                </div>
              </div>
              <div class="col-3 pl-1">
                <div class="form-group">
                  <label>Tot</label>
                  <input :disabled="!hasMinTime" type="time" min="07:00" max="17:00" class="form-control mx-0" :step="300" v-model="note.ends_at_time">
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Notitie</label>
              <div class="form-control" style="height: auto; max-height: 60px;">
                <editor-menu-bubble :editor="editor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, menu }">
                    <div
                      class="menububble"
                      :class="{ 'is-active': menu.isActive }"
                      :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                    >
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold"
                      >
                        <i class="fas fa-bold"></i>
                      </button>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic"
                      >
                        <i class="fas fa-italic"></i>
                      </button>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.underline() }"
                        @click="commands.underline"
                      >
                        <i class="fas fa-underline"></i>
                      </button>
                    </div>
                  </editor-menu-bubble>
                <editor-content class="editor__content" style="min-height: 60px;" v-model="note.body" :editor="editor" />
              </div>
            </div>

            <div class="form-group">
              <label>Notitie voor Specialist</label>
              <div class="form-control" style="height: auto; max-height: 60px;">
                <editor-menu-bubble :editor="specialistEditor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, menu }">
                    <div
                      class="menububble"
                      :class="{ 'is-active': menu.isActive }"
                      :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                    >
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold"
                      >
                        <i class="fas fa-bold"></i>
                      </button>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic"
                      >
                        <i class="fas fa-italic"></i>
                      </button>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.underline() }"
                        @click="commands.underline"
                      >
                        <i class="fas fa-underline"></i>
                      </button>
                    </div>
                  </editor-menu-bubble>
                <editor-content class="editor__content" style="min-height: 60px;" v-model="note.specialist_editor" :editor="specialistEditor" />
              </div>
            </div>


            <div class="form-group">
              <label>Leerlingen</label>
              <studentselector @save="SetStudents" :key="`student-selector-${note.id}`" :students="note.students" :groups="groups" :columns="4" />
                            
            </div>

            <div class="p-2">
              <label>Soort zorgafspraak</label>
              <select v-model="note.planned_note_category_id" class="custom-select custom-select-md">
                <option v-for="category in categories" :value="category.id">{{ category.name }}</option>
              </select>
            </div>

            <div class="p-2">

              <toggle-button v-model="note.show_for_student" :sync="true" :labels="{checked: 'Zichtbaar op weektaak van kind', unchecked: 'Niet zichtbaar op weektaak'}" :fontSize="12" :width="225" />
              <div class="form-group" v-show="note.show_for_student">
                <label>Notitie voor kind</label>
                <div class="form-control" style="height: auto; max-height: 60px;">
                  <editor-menu-bubble :editor="studentEditor" :keep-in-bounds="keepInBounds" v-slot="{ commands, isActive, menu }">
                      <div
                        class="menububble"
                        :class="{ 'is-active': menu.isActive }"
                        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                      >
                        <button
                          class="menububble__button"
                          :class="{ 'is-active': isActive.bold() }"
                          @click="commands.bold"
                        >
                          <i class="fas fa-bold"></i>
                        </button>
                        <button
                          class="menububble__button"
                          :class="{ 'is-active': isActive.italic() }"
                          @click="commands.italic"
                        >
                          <i class="fas fa-italic"></i>
                        </button>
                        <button
                          class="menububble__button"
                          :class="{ 'is-active': isActive.underline() }"
                          @click="commands.underline"
                        >
                          <i class="fas fa-underline"></i>
                        </button>
                      </div>
                    </editor-menu-bubble>
                  <editor-content class="editor__content" style="min-height: 60px;" v-model="note.student_body" :editor="studentEditor" />
                  </div>
                </div>

            </div>

            <div class="p-2" v-if="false">
              <label>Ruimte voor geschreven notitie</label>
              <select v-model="note.extra_lines" class="custom-select custom-select-md">
                <option value="0">Geen extra lijntjes</option>
                <option value="1">1 extra lijntje</option>
                <option value="2">2 extra lijntjes</option>
                <option value="3">3 extra lijntjes</option>
              </select>
            </div>

          </div>

          <div class="modal-footer bg-light d-block py-3" v-if="!ShowRepeatOption">
            <div class="d-flex justify-content-between">
              <button v-if="!IsNew" @click="Delete" class="btn btn-danger" type="button"><i class="fa fa-trash"></i></button>
              <div v-if="IsNew"></div>
              <div>
                <button @click="Cancel" class="btn btn-label" type="button">Annuleren</button>
                <button @click="Save" class="btn btn-primary" :disabled="!IsValid" type="button">Opslaan</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="modal fade" id="modalScreenRepeat" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content" v-if="note.is_repeating && copyOrRepeat == null">
          <div class="modal-header bg-light">
            <h5 class="modal-title">Herhaalde afspraak</h5>
            <button @click="HideRepeatModal" type="button" class="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          
          <div class="modal-body">
            
            <div class="row">

              <div class="col">
                

                <dl>
                  <dt>Startdatum</dt>
                  <dd>{{ note.repeat_starts_at }}</dd>

                  <dt>Hoe vaak herhalen</dt>
                  <dd v-if="note.repeat_weeks_between == 1">Iedere week</dd>
                  <dd v-if="note.repeat_weeks_between == 2">Om de week</dd>
                  <dd v-if="note.repeat_weeks_between == 3">Eens per drie weken</dd>
                  <dd v-if="note.repeat_weeks_between == 4">Eens per vier weken</dd>
                  <dd v-if="note.repeat_weeks_between == 5">Eens per vijf weken</dd>
                  <dd v-if="note.repeat_weeks_between == 6">Eens per zes weken</dd>
                  <dd v-if="note.repeat_weeks_between == 7">Eens per zeven weken</dd>
                  <dd v-if="note.repeat_weeks_between == 8">Eens per acht weken</dd>


                  <dt>Op welke dag(en)</dt>
                  <dd>
                    <div class="d-flex justify-content-between">
                      <toggle-button v-model="note.repeat_day_ma" :disabled="true" :labels="{checked: 'maandag', unchecked: 'maandag'}" :fontSize="10" :width="84" class="d-block"/>
                      <toggle-button v-model="note.repeat_day_di" :disabled="true" :labels="{checked: 'dinsdag', unchecked: 'dinsdag'}" :fontSize="10"  :width="84" class="d-block"/>
                      <toggle-button v-model="note.repeat_day_wo" :disabled="true" :labels="{checked: 'woensdag', unchecked: 'woensdag'}" :fontSize="10"  :width="84" class="d-block"/>
                      <toggle-button v-model="note.repeat_day_do" :disabled="true" :labels="{checked: 'donderdag', unchecked: 'donderdag'}" :fontSize="10"  :width="92" class="d-block"/>
                      <toggle-button v-model="note.repeat_day_vr" :disabled="true" :labels="{checked: 'vrijdag', unchecked: 'vrijdag'}" :fontSize="10"  :width="84" class="d-block"/>
                    </div>
                  </dd>

                  <dt>Tot wanneer herhalen</dt>
                  <dd v-if="note.show_repeat_ends_at == ''">Tot {{note.repeat_ends_after }} keer</dd>
                  <dd v-if="note.show_repeat_ends_at != ''">Tot {{note.show_repeat_ends_at }}</dd>
                  
                </dl>

                
              </div>
              <div class="row text-center" style="text-wrap: balance;">
                <div class="col-12">
                  <button @click="CancelRepeat" class="btn btn-outline-primary">Onderbreek reeks en verwijder toekomstige afspraken</button>
                </div>
                <div class="col-11 px-2 mx-auto">
                  <small>Als je de reeks onderbreekt, worden alle afspraken na {{ note.day }} {{ note.show_date }} verwijderd.</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-content" v-if="!note.is_repeating">
          <div class="modal-header bg-light">
            <h5 v-if="copyOrRepeat != null" class="modal-title">Afspraak herhalen</h5>
            <h5 v-if="copyOrRepeat == null" class="modal-title">Wat wil je doen?</h5>
            <button @click="HideRepeatModal" type="button" class="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center" v-if="copyOrRepeat == null">
            <button class="btn btn-outline-success" @click="copy"><i class="fa fa-copy"></i> Eenmalig kopiëren</button> <span class="text-muted mx-1">of</span> 
            <button class="btn btn-outline-success" @click="copyOrRepeat = 'repeat'"><i class="fas fa-redo"></i> Repeterende afspraak maken</button>
          </div>
          <div class="modal-body" v-if="copyOrRepeat == 'repeat'">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Wanneer herhalen</label>
                  <select v-model="note.repeat_weeks_between" class="custom-select custom-select-md">
                    <option :value="1">Iedere week</option>
                    <option :value="2">Om de week</option>
                    <option :value="3">Eens per drie weken</option>
                    <option :value="4">Eens per vier weken</option>
                    <option :value="5">Eens per vijf weken</option>
                    <option :value="6">Eens per zes weken</option>
                    <option :value="7">Eens per zeven weken</option>
                    <option :value="8">Eens per acht weken</option>
                  </select>      
                </div>
              </div>
            </div>

            

            <div class="p-2">
              <label>Op welke dag(en)</label>
              <div class="d-flex justify-content-between">
                  <toggle-button v-model="note.repeat_day_ma" :sync="true" :labels="{checked: 'maandag', unchecked: 'maandag'}" :fontSize="10" :width="84" class="d-block"/>
                  <toggle-button v-model="note.repeat_day_di" :sync="true" :labels="{checked: 'dinsdag', unchecked: 'dinsdag'}" :fontSize="10"  :width="84" class="d-block"/>
                  <toggle-button v-model="note.repeat_day_wo" :sync="true" :labels="{checked: 'woensdag', unchecked: 'woensdag'}" :fontSize="10"  :width="84" class="d-block"/>
                  <toggle-button v-model="note.repeat_day_do" :sync="true" :labels="{checked: 'donderdag', unchecked: 'donderdag'}" :fontSize="10"  :width="92" class="d-block"/>
                  <toggle-button v-model="note.repeat_day_vr" :sync="true" :labels="{checked: 'vrijdag', unchecked: 'vrijdag'}" :fontSize="10"  :width="84" class="d-block"/>
              </div>
            </div>


            <div class="p-2">
              <label>Hoe herhalen</label>
              <div class="">
                <div class="form-check">
                  <input class="form-check-input" v-model="repeatKind" type="radio" id="until_date" value="date" name="repeat_kind">
                  <label class="form-check-label" for="until_date">
                    Tot datum
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" v-model="repeatKind" type="radio" id="number_of_times" value="number" name="repeat_kind">
                  <label class="form-check-label" for="number_of_times">
                    Aantal keer
                  </label>
                </div>

              </div>
            </div>
            <div class="p-2 row">
              <div class="col-6">
                <div class="form-group" v-if="repeatKind == 'date'">
                  <label>Herhalen tot</label>
                  <div class="d-flex justify-content-between">
                    <input type="date" :min="minDate" :max="maxDate" class="form-control" v-model="note.repeat_ends_at" autofocus>
                  </div>
                </div>
                <div class="form-group" v-if="repeatKind == 'number'">
                  <label>Aantal keren herhalen</label>
                  <div class="d-flex justify-content-between">
                    <input type="number" :min="0" class="form-control" v-model="note.repeat_ends_after" autofocus>
                  </div>
                </div>

              </div>
            </div>

          </div>

          

          <div class="modal-footer bg-light d-block py-3" v-if="copyOrRepeat != null">
            <div class="d-flex justify-content-between">
              <button @click="HideRepeatModal" class="btn btn-label" type="button">Annuleren</button>
              <div>
                <button @click="SaveRepeat" class="btn btn-success" :disabled="!IsRepeatValid" type="button">Herhaling opslaan</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import PlannedNotesPrint from './plannedNotesPrint.vue'
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
  Bold,
  Italic,
  Strike,
  Underline,
  Placeholder,
  History,
} from 'tiptap-extensions'

export default {

  name: 'plannedNotes',
  props: ["from_date", "until_date", "group_ids", "dates", "planned_note_category_id", "show_all", "week"],
  components: {
    EditorContent,
    EditorMenuBubble,
    PlannedNotesPrint
  },
  watch: {
    note: {
      handler: function(oldVal, newVal) {
        this.editor.setContent(this.note.body)
        this.specialistEditor.setContent(this.note.specialist_body)
        this.studentEditor.setContent(this.note.student_body)
      },
      deep: true
    }
  },
  data () {
    return {
      theDates: [],
      loaded: false,
      group: {},
      showPast: false,
      showFuture: false,
      categories: [],
      saving: false,
      groups: [],
      repeatKind: '',
      times: [],
      copyOrRepeat: null,
      days: [],
      editor: null,
      selectedWeek: null,
      specialistEditor: null,
      studentEditor: null,
      keepInBounds: true,
      editableBody: '',
      editableSpecialistBody: '',
      editableStudentBody: '',
      repeatEditSelection: null,
      notes: [],
      note: {},
      studentNote: {}
    }
  },
  computed:{
    showLoadMoreButton(){
      return this.notes.length > 0 && this.notes[this.notes.length-1].is_far_in_future && !this.showFuture && !this.show_all
    },
    showBlankSlate(){
      return this.loaded && this.notes.length == 0
    },
    ShowRepeatOption(){
      return this.note.is_repeating && this.repeatEditSelection == null
    },
    IsNew() {
      return this.note.id == null || this.note.id.length > 10
    },
    IsRepeatValid(){
      return this.IsValid && this.repeatKind != '' &&
        (this.note.repeat_day_ma || this.note.repeat_day_di || this.note.repeat_day_wo || this.note.repeat_day_do || this.note.repeat_day_vr) &&
      (
        (this.repeatKind == 'date' && this.note.repeat_ends_at > this.note.starts_at_time) 
        ||
        true
        )
    },
    IsValid() {
      return this.note.starts_at_date != null && 
      !this.saving &&
      this.note.ends_at_time != null && 
      // this.note.students.length > 0 &&
      this.note.starts_at_time <= this.note.ends_at_time
    },
    hasMinDate() {
      return this.note.starts_at_date != undefined
    },
    hasMinTime() {
      return this.note.starts_at_time != undefined
    },
    minDate(){
      return `${this.from_date}T07:30`
    },
    maxDate(){
      return `${this.until_date}T16:30`
    },
    endMinDate(){
      if (this.note.starts_at){
        return this.note.starts_at  
      }
      return `${this.from_date}T07:30`
    },
    calculatedStartTime() {
      return this.note.starts_at_time ?? '08:00'
    },
    startsAt() {
      return `${this.note.starts_at_date}T${this.calculatedStartTime}`
    },
    endsAt() {
      return `${this.note.starts_at_date}T${this.note.ends_at_time ?? this.calculatedStartTime}`
    },
    paramString(){
      return `from_date=${this.from_date}&until_date=${this.until_date}&group_ids=${this.group_ids}&planned_note_category_id=${this.planned_note_category_id}&repeat_kind=${this.repeatKind}&repeat_mutation_option=${this.repeatEditSelection}&week=${this.selectedWeek}`
    }
  },
  mounted() {
    this.theDates = this.dates;
    this.selectedWeek = this.week;
    this.LoadStudents();
    this.LoadCategories();
    this.LoadNotes();
    this.LoadEditor();
    this.LoadSpecialistEditor();
    this.LoadStudentEditor();
  },
  methods: {
    incrementWeek() {
      // Converteer de geselecteerde week naar een Moment-object
      const moment = window.moment(this.selectedWeek, 'YYYY-[W]WW');

      // Verhoog de week met 1
      moment.add(1, 'weeks');

      // Formatteer de datum als een string in het vereiste formaat
      this.selectedWeek = moment.format('YYYY-[W]WW');
      this.LoadNotes()
    },
    decrementWeek() {
      // Converteer de geselecteerde week naar een Moment-object
      const moment = window.moment(this.selectedWeek, 'YYYY-[W]WW');
      // Verlaag de week met 1
      moment.subtract(1, 'weeks');

      // Formatteer de datum als een string in het vereiste formaat
      this.selectedWeek = moment.format('YYYY-[W]WW');
      this.LoadNotes()
    },
    RepeatTitle(note){
      return note.is_repeating ? 'Herhaald' : 'Herhalen'
    },

    Cancel() {
      this.HideModal();
    },
    SaveRepeat(){
      if (this.repeatKind == 'number'){
        this.note.repeat_ends_at = null
      }
      this.Update('repeat');
    },
    
    Save() {
      this.saving = true
      if (this.IsNew){
        this.SaveNew()
      } else {
        this.Update('')
      }
    },
    copy() {
      this.HideRepeatModal();
      // return axios.put(`/planned_notes/${this.note.id}.json?${this.paramString}`, {
      return axios.get(`/planned_notes/${this.note.id}/copy.json`)
        .then(response => {
          console.log(response)
          
          this.Edit(response.data)

        }).catch( error => { 
          console.log("fout bij opslaan...")
          console.log(error.response)
          console.log(error)
        })  
    },
    SaveNew(){
      this.repeatKind = ''
      return axios.post(`/planned_notes.json?${this.paramString}`, {
          planned_note: this.NoteForBackend('')
        })
        .then(response => {
          console.log(response)
          this.notes = response.data.notes
          this.times = response.data.times
          this.HideModal();
          this.saving = false
        }).catch( error => { 
          console.log("fout bij opslaan...")
          console.log(error.response)
          console.log(error)
        })
    },
    CancelRepeat(){
      return axios.get(`/planned_notes/${this.note.id}/cancel_repeat.json?${this.paramString}`)
        .then(response => {
          console.log(response)
          this.notes = response.data.notes
          this.times = response.data.times
          this.HideModal();
          this.HideRepeatModal();
          this.saving = false
        }).catch( error => { 
          console.log("fout bij opslaan...")
          console.log(error.response)
          console.log(error)
        })
    },
    Update(kind){
      return axios.put(`/planned_notes/${this.note.id}.json?${this.paramString}`, {
          planned_note: this.NoteForBackend(kind)
        })
        .then(response => {
          console.log(response)
          this.notes = response.data.notes
          this.times = response.data.times
          this.HideModal();
          this.HideRepeatModal();
          this.saving = false
        }).catch( error => { 
          console.log("fout bij opslaan...")
          console.log(error.response)
          console.log(error)
        })
    },
    Delete(){
      return axios.delete(`/planned_notes/${this.note.id}.json?${this.paramString}`)
        .then(response => {
          console.log(response)
          this.notes = response.data.notes
          this.times = response.data.times
          this.HideModal();
          this.saving = false
        }).catch( error => { 
          console.log("fout bij opslaan...")
          console.log(error.response)
          console.log(error)
        })
    },
    SetDefaultEndTime(){
      console.log("setting default?")
      console.log(this.note.ends_at_time)
      if (this.note.ends_at_time == null){
        console.log("setting time!")
        this.note.ends_at_time = this.note.starts_at_time
      }
    },
    SetNote(note){
      this.note = Object.assign({}, note)
      this.note.repeat_day_ma = this.note.starts_at_day == 1
      this.note.repeat_day_di = this.note.starts_at_day == 2
      this.note.repeat_day_wo = this.note.starts_at_day == 3
      this.note.repeat_day_do = this.note.starts_at_day == 4
      this.note.repeat_day_vr = this.note.starts_at_day == 5
      this.note.repeat_ends_at = this.note.default_repeat_date
      this.note.repeat_ends_after = this.note.repeat_ends_after
    },
    SetStudents(students){
      this.note.students = students
    },
    Guid(){
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
          .replace(/[xy]/g, function (c) {
              const r = Math.random() * 16 | 0, 
                  v = c == 'x' ? r : (r & 0x3 | 0x8);
              return v.toString(16);
          });
    },
    ClearNote(){
      this.editableBody = ''
      this.editableSpecialistBody = ''
      this.editableStudentBody = ''
      this.note = {
        id: this.Guid(),
        starts_at_date: null,
        starts_at_time: null,
        ends_at_time: null,
        students: [],
        extra_lines: 0,
        show_for_student: false,
        body: '',
        specialist_body: '',
        student_body: '',
        repeat_weeks_between: 1,
        planned_note_category_id: this.planned_note_category_id
      }
      
      if (this.planned_note_category_id === undefined){
        this.note.planned_note_category_id = this.categories[0].id
      }
    },
    LoadEditor(){

      this.editor = new Editor({
        extensions: [
          new Placeholder({
            emptyClass: 'is-empty',
            emptyNodeText: "Notitie",
            showOnlyWhenEditable: false,
          }),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        disableInputRules: true,
        content: this.note ? this.note.body : '',
        onUpdate: ({ getHTML }) => {
          this.editableBody = getHTML()
          // this.lessonLevel.task = getHTML()
          // if (this.islocked) {
          //   this.$emit('lockedUpdate', this.lessonLevel, 'task')
          // }
        },
        onBlur: ({ event,  state, view }) => {
          // this.submitChanges('task')
          this.note.body = this.editableBody
        }
      })
    },
    LoadStudentEditor(){
      
      this.studentEditor = new Editor({
        extensions: [
          new Placeholder({
            emptyClass: 'is-empty',
            emptyNodeText: "Notitie voor leerling",
            showOnlyWhenEditable: false,
          }),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        disableInputRules: true,
        content: this.note ? this.note.student_body : '',
        onUpdate: ({ getHTML }) => {
          this.editableStudentBody = getHTML()
        },
        onBlur: ({ event,  state, view }) => {
          // this.submitChanges('task')
          this.note.student_body = this.editableStudentBody
        }
      })

    },
    LoadSpecialistEditor(){
      
      this.specialistEditor = new Editor({
        extensions: [
          new Placeholder({
            emptyClass: 'is-empty',
            emptyNodeText: "Notitie voor specialist",
            showOnlyWhenEditable: false,
          }),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        disableInputRules: true,
        content: this.note ? this.note.specialist_body : '',
        onUpdate: ({ getHTML }) => {
          this.editableSpecialistBody = getHTML()
        },
        onBlur: ({ event,  state, view }) => {
          // this.submitChanges('task')
          this.note.specialist_body = this.editableSpecialistBody
        }
      })
    },
    NoteForBackend(kind){
      let note = {}
      if (kind == 'repeat'){
          note = {
            repeat_weeks_between: this.note.repeat_weeks_between,
            repeat_day_ma: this.note.repeat_day_ma,
            repeat_day_di: this.note.repeat_day_di,
            repeat_day_wo: this.note.repeat_day_wo,
            repeat_day_do: this.note.repeat_day_do,
            repeat_day_vr: this.note.repeat_day_vr,
            repeat_ends_at: this.note.repeat_ends_at,
            repeat_ends_after: this.note.repeat_ends_after
          }
      } else {
          note = {
            starts_at: this.startsAt,
            ends_at: this.endsAt,
            extra_lines: this.note.extra_lines,
            planned_note_category_id: this.note.planned_note_category_id,
            body: this.note.body,
            student_body: this.note.student_body,
            specialist_body: this.note.specialist_body,
            show_for_student: this.note.show_for_student,
            student_ids: this.note.students.map(student => student.id)
          }


      }
      
      if (this.note.id != null){
        note.id == this.note.id
      }
      return note
    },
    LoadCategories(){
      return axios.get(`/planned_note_categories.json`).
      then(response => {
        this.categories = response.data
      }).finally(() => {
        // this.loading = false
      })
    },
    LoadStudents(){
      return axios.get(`/groups.json?group_ids=all`).
      then(response => {
        this.groups = response.data
      }).finally(() => {
        // this.loading = false
      })
    },
    LoadNotes(){
      let params = ''
      if (this.selectedWeek != null) {
        params = `/planned_notes.json?group_ids=${this.group_ids}&week=${this.selectedWeek}&planned_note_category_id=${this.planned_note_category_id}`
      } else {
        params = `/planned_notes.json?group_ids=${this.group_ids}&from_date=${this.from_date}&until_date=${this.until_date}&planned_note_category_id=${this.planned_note_category_id}`
      }
      return axios.get(params).
      then(response => {
        console.log(response.data)
        this.notes = response.data.notes
        this.times = response.data.times
        this.days = response.data.days
        this.theDates = response.data.days
        this.loaded = true
      }).finally(() => {
        // this.ShowModal()
        // this.loading = false
      })
    },
    Repeat(note) {
      this.SetNote(note)
      this.ShowRepeatModal()
    },    
    Edit(note) {
      this.repeatEditSelection = null
      console.log('setting body')
      console.log(note.body)
      this.SetNote(note)
      this.repeatKind = ''
      this.editableBody = note.body
      this.editableStudentBody = note.student_body
      this.ShowModal()
    },
    New() {
      this.ClearNote()
      this.ShowModal()
    },
    HideModal() {
      $("#modalScreen").modal('hide')
    },
    ShowModal() {
      $("#modalScreen").modal('show')
      
    },
    HideRepeatModal() {
      $("#modalScreenRepeat").modal('hide')
    },
    ShowRepeatModal() {
      this.copyOrRepeat = null
      this.repeatEditSelection = null
      $("#modalScreenRepeat").modal('show')
      
    }, 
    beforeDestroy(){
      this.editor.destroy()
      this.studentEditor.destroy()
    }
  }
}
</script>

<style lang="css">
  .planned-note .modal-body {
    max-height: 70vh;
    overflow: auto;
  }
  .planned-note .ProseMirror p:last-child{
    margin-bottom: 0px;
  }
  .planned-note .ProseMirror-focused{
    border:  none;
    outline:  none;
  }

   .planned-note .menububble{
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 20;
    background: #000;
    border-radius: 5px;
    padding: .3rem;
    margin-bottom: .5rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .2s,visibility .2s;
    transition: opacity .2s,visibility .2s;
  } 
  .planned-note .menububble.is-active{
    opacity: 1;
    visibility: visible;
  }

  .planned-note .menububble__button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #fff;
    padding: .2rem .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    cursor: pointer;
}
.editor__content {
  display: flex;

}
.editor__content > div {
  flex-grow: 1;
}

tbody p {
  margin-bottom:  0;
}
</style>